import { useState } from "react";
import { Button, Col, Form, Row, Card } from "react-bootstrap";
import { formatDate3 } from "./../function";
import httpRequest from "../HttpRequestService";

const Pass = (props) => {

  const [ussd, setUssd] = useState('#111*1*6*1#');
  const [ussdCode, setUssdCode] = useState(null);
  const [name, setName] = useState(props.pass.name);
  const [canRetrait, setCanRetrait] = useState(props.pass.canRetrait);
  const [canUssd, setCanUssd] = useState(props.pass.canUssd);
  const [code, setCode] = useState(props.pass.code);
  const [isTpe, setIsTpe] = useState(props.pass.isTpe);
  const [isTpeRetrait, setIsTpeRetrait] = useState(props.pass.isTpeRetrait);
  const [isCashPoint, setIsCashPoint] = useState(props.pass.isCashPoint);
  const [imei, setImei] = useState(props.pass.imei);
  //const [url, setUrl] = useState(props.pass.url);
  const [p_adresse, setP_adresse] = useState(props.pass.proprietaire.adresse);
  const [p_contact, setP_contact] = useState(props.pass.proprietaire.contact);
  const [p_name, setP_name] = useState(props.pass.proprietaire.name);
  const [p_nameResponsable, setP_nameResponsable] = useState(props.pass.proprietaire.nameResponsable);

  const [operateur_first_name, setOperateur_first_name] = useState(props.pass.operateur[0] ? props.pass.operateur[0].name : '');
  const [operateur_first_operateur, setOperateur_first_operateur] = useState(props.pass.operateur[0] ? props.pass.operateur[0].operateur : '');
  const [operateur_first_phone, setOperateur_first_phone] = useState(props.pass.operateur[0] ? props.pass.operateur[0].phone : '');

  const [operateur_second_name, setOperateur_second_name] = useState(props.pass.operateur[1] ? props.pass.operateur[1].name : '');
  const [operateur_second_operateur, setOperateur_second_operateur] = useState(props.pass.operateur[1] ? props.pass.operateur[1].operateur : '');
  const [operateur_second_phone, setOperateur_second_phone] = useState(props.pass.operateur[1] ? props.pass.operateur[1].phone : '');

  const updatePass = async () => {
    try {
      const response = await httpRequest(`${props.token}/myPasserelle/Update`, {
        data: {
          id: props.pass._id,
          name,
          canRetrait,
          canUssd,
          code,
          isTpe,
          isTpeRetrait,
          isCashPoint,
          imei,
          proprietaire: {
            adresse: p_adresse,
            contact: p_contact,
            name: p_name,
            nameResponsable: p_nameResponsable
          },
          operateur: [
            {
              name: operateur_first_name,
              operateur: operateur_first_operateur,
              phone: operateur_first_phone
            },
            {
              name: operateur_second_name,
              operateur: operateur_second_operateur,
              phone: operateur_second_phone
            }
          ]
        }
      }, false);
      if (response.data) {
        props.load();
      }
      //setLoading(false);
    } catch (error) {
      //setLoading(false);
      //setErreur(error.response.data.message);
      console.log(error.response.data);
    }
  };

  const sendUssd = async () => {
    try {
      const response = await httpRequest(`${props.token}/myPasserelle/SendUssd`, {
        data: {
          imei,
          ussd,
          code: ussdCode
        }
      }, false);
    } catch (error) {
      console.log(error.response.data);
    }
  }

  return (
    <>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Control type="text" placeholder="USSD" value={ussd} onChange={(event) => setUssd(event.target.value)} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Control type="text" placeholder="USSD CODE" value={ussdCode} onChange={(event) => setUssdCode(event.target.value == '' ? null : event.target.value)} />
          </Form.Group>
        </Col>
        <Col xs={2}>
          <Button variant="danger" onClick={sendUssd}>Envoyer</Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5 className="text-success">Telma: {props.pass.solde.telma ? props.pass.solde.telma : 0} Ar</h5>
        </Col>
        <Col>
          <h5 className="text-warning">Orange: {props.pass.solde.orange ? props.pass.solde.orange : 0} Ar</h5>
        </Col>
        <Col>
          <h5 className="text-danger">Airtel: {props.pass.solde.airtel ? props.pass.solde.airtel : 0} Ar</h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label> Nom de l'organisation</Form.Label>
              <Form.Control type="text" value={p_name} onChange={(event) => setP_name(event.target.value)} required />
            </Form.Group>
          </Form>
        </Col>
        <Col>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label> Adresse de l'organisation</Form.Label>
              <Form.Control type="text" value={p_adresse} onChange={(event) => setP_adresse(event.target.value)} required />
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label> Contact de l'organisation</Form.Label>
              <Form.Control type="text" placeholder="" value={p_contact} onChange={(event) => setP_contact(event.target.value)} />
            </Form.Group>
          </Form>
        </Col>
        <Col>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label> Responsable de l'organisation</Form.Label>
              <Form.Control type="text" placeholder="" value={p_nameResponsable} onChange={(event) => setP_nameResponsable(event.target.value)} />
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label> Nom du passerelle</Form.Label>
              <Form.Control type="text" value={name} onChange={(event) => setName(event.target.value)} required />
            </Form.Group>
          </Form>
        </Col>
        <Col>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label> IMEI du passerelle</Form.Label>
              <Form.Control type="text" placeholder="IMEI" value={imei} onChange={(event) => setImei(event.target.value)} />
            </Form.Group>
          </Form>
        </Col>
        <Col>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label> Code du passerelle</Form.Label>
              <Form.Control type="text" placeholder="code" value={code} onChange={(event) => setCode(event.target.value)} />
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label> P1 nom</Form.Label>
              <Form.Control type="text" value={operateur_first_name} onChange={(event) => setOperateur_first_name(event.target.value)} required />
            </Form.Group>
          </Form>
        </Col>
        <Col>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label> P1 opérateur</Form.Label>
              <Form.Select aria-label="Opérateur" value={operateur_first_operateur} onChange={(event) => setOperateur_first_operateur(event.target.value)}>
                <option value="telma">Telma</option>
                <option value="orange">Orange</option>
                <option value="airtel">Airtel</option>
              </Form.Select>
              {/* <Form.Control type="text" value={operateur_first_operateur} onChange={(event) => setOperateur_first_operateur(event.target.value)} /> */}
            </Form.Group>
          </Form>
        </Col>
        <Col>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label> P1 numéro</Form.Label>
              <Form.Control type="text" value={operateur_first_phone} onChange={(event) => setOperateur_first_phone(event.target.value)} />
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label> P2 nom</Form.Label>
              <Form.Control type="text" value={operateur_second_name} onChange={(event) => setOperateur_second_name(event.target.value)} required />
            </Form.Group>
          </Form>
        </Col>
        <Col>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label> P2 opérateur</Form.Label>
              <Form.Select aria-label="Opérateur" value={operateur_second_operateur} onChange={(event) => setOperateur_second_operateur(event.target.value)}>
                <option value="telma">Telma</option>
                <option value="orange">Orange</option>
                <option value="airtel">Airtel</option>
              </Form.Select>
              {/* <Form.Control type="text" value={operateur_first_operateur} onChange={(event) => setOperateur_first_operateur(event.target.value)} /> */}
            </Form.Group>
          </Form>
        </Col>
        <Col>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label> P2 numéro</Form.Label>
              <Form.Control type="text" value={operateur_second_phone} onChange={(event) => setOperateur_second_phone(event.target.value)} />
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Check // prettier-ignore
            type="checkbox"
            id={`isTpe`}
            label={`isTpe`}
            checked={isTpe}
            onChange={(event) => setIsTpe(event.target.checked)}
          />
        </Col>
        <Col>
          <Form.Check // prettier-ignore
            type="checkbox"
            id={`isTpeRetrait`}
            label={`isTpeRetrait`}
            checked={isTpeRetrait}
            onChange={(event) => setIsTpeRetrait(event.target.checked)}
          />
        </Col>
        <Col>
          <Form.Check // prettier-ignore
            type="checkbox"
            id={`isCashPoint`}
            label={`isCashPoint`}
            checked={isCashPoint}
            onChange={(event) => setIsCashPoint(event.target.checked)}
          />
        </Col>
        <Col>
          <Form.Check // prettier-ignore
            type="checkbox"
            id={`canRetrait`}
            label={`canRetrait`}
            checked={canRetrait}
            onChange={(event) => setCanRetrait(event.target.checked)}
          />
        </Col>
        <Col>
          <Form.Check // prettier-ignore
            type="checkbox"
            id={`canUssd`}
            label={`canUssd`}
            checked={canUssd}
            onChange={(event) => setCanUssd(event.target.checked)}
          />
        </Col>
      </Row>
      <br />
      <Button variant="danger" onClick={updatePass}>Mettre à jour</Button>
    </>
  );
}

export default Pass;